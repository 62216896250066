<template>
    <div id="page-lap-sp3lb1">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-select
                        :options="option_bulan"
                        size="sm"
                        v-model="data_search.bulan_awal"
                      ></b-form-select>

                      <b-form-select
                        :options="option_tahun"
                        size="sm"
                        v-model="data_search.tahun_awal"
                        class="ml-3"
                      ></b-form-select>
                    </div>
                    
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-select
                        :options="option_bulan"
                        size="sm"
                        v-model="data_search.bulan_akhir"
                      ></b-form-select>

                      <b-form-select
                        :options="option_tahun"
                        size="sm"
                        v-model="data_search.tahun_akhir"
                        class="ml-3"
                      ></b-form-select>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-card>
                    <div style="width:100%;display:flex">
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                        <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                      </div>
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                        <b-button variant="outline-primary" size="sm">
                          <b-form-checkbox
                            id="check_puskesmas"
                            name="check_puskesmas"
                            v-model="checked"
                            @change="toggleSelect()"
                          >
                            <span class="pt-1">Pilih Semua</span>
                          </b-form-checkbox>
                        </b-button>
                      </div>
                    </div>
                    <hr>
                    <b-row style="padding-left:7.5px;margin-top:15px;">
                      <b-col v-for="(item, idx) in options_puskesmas" :key="idx" cols="12" sm="12" md="6" lg="4" xl="4">
                          <b-form-checkbox
                            v-model="item.checked"
                            @change="addSelect()"
                          >
                            {{ item.nama_puskesmas }}
                          </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
                <!-- <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-card>
                    <div style="width:100%;display:flex">
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                        <h6 style="margin:0;font-weight:bold">Poli / Ruangan</h6>
                      </div>
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                        <b-button variant="outline-primary" size="sm">
                          <b-form-checkbox
                            id="check_poli"
                            name="check_poli"
                            v-model="checked_poli"
                            @change="toggleSelectPoli()"
                            
                          >
                            <span class="pt-1">Pilih Semua</span>
                          </b-form-checkbox>
                        </b-button>
                      </div>
                    </div>
                    <hr>
                    <b-row style="padding-left:7.5px;margin-top:15px;">
                      <b-col v-for="(item, idx) in options_poli" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                          <b-form-checkbox
                            v-model="item.checked"
                            @change="addSelectPoli()"
                          >
                            {{ item.nama_poli }}
                          </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col> -->
              </b-row>

              <!-- <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row> -->

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                    <!-- <b-button variant="info" class="mr-2">Export Template</b-button>
                    <b-button variant="success" class="mr-2">Export to Excel</b-button> -->
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h5 style="text-align:center"><strong>LAPORAN BULANAN - SP3 LB1</strong></h5>
                </b-col>
              </b-row>

              <!-- <b-row style="margin-top:15px;">
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        Kode Puskesmas
                      </template>
                      <b-form-input
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        Puskesmas
                      </template>
                      <b-form-input
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        JUmlah Puskesmas Pembantu Yang Ada
                      </template>
                      <b-form-input
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        Jumlah Poskesdes/Bidan di Desa
                      </template>
                      <b-form-input
                      ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        Bulan
                      </template>
                      <b-form-input
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        Tahun
                      </template>
                      <b-form-input
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        Jumlah Puskesmas Pembantu Yang Lapor
                      </template>
                      <b-form-input
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="4">
                      <template v-slot:label>
                        Jumlah Poskesdes/Bidan Yang Lapor
                      </template>
                      <b-form-input
                      ></b-form-input>
                    </b-form-group>
                </b-col>
              </b-row> -->

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-table-simple responsive class="custom-table" >
                    <b-thead >
                        <b-tr>
                            <b-th rowspan="4">No. Urut</b-th>
                            <b-th rowspan="4">Kode ICD-10</b-th>
                            <b-th rowspan="4">Jenis Penyakit</b-th>
                            <b-th colspan="58">JUMLAH PENDERITA</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th colspan="4">0 - 7 hr</b-th>
                            <b-th colspan="4">8 - 28 hr</b-th>
                            <b-th colspan="4">29 hr - 1 thn</b-th>
                            <b-th colspan="4">1 - 4 thn</b-th>
                            <b-th colspan="4">5 - 9 thn</b-th>
                            <b-th colspan="4">10 - 14 thn</b-th>
                            <b-th colspan="4">15 - 19 thn</b-th>
                            <b-th colspan="4">20 - 44 thn</b-th>
                            <b-th colspan="4">45 - 54 thn</b-th>
                            <b-th colspan="4">55 - 59 thn</b-th>
                            <b-th colspan="4">60 - 64 thn</b-th>
                            <b-th colspan="4">65 - 69 thn</b-th>
                            <b-th colspan="4">70 thn</b-th>
                            <b-th colspan="6">Jumlah</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Baru</b-th>
                            <b-th colspan="2">Lama</b-th>
                            <b-th colspan="2">Total</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                            <b-th>L</b-th>
                            <b-th>P</b-th>
                        </b-tr>
                        <!-- <b-tr>
                            <b-th>1</b-th>
                            <b-th>2</b-th>
                            <b-th>3</b-th>
                            <b-th>4</b-th>
                            <b-th>5</b-th>
                            <b-th>6</b-th>
                            <b-th>7</b-th>
                            <b-th>8</b-th>
                            <b-th>9</b-th>
                            <b-th>10</b-th>
                            <b-th>11</b-th>
                            <b-th>12</b-th>
                            <b-th>13</b-th>
                            <b-th>14</b-th>
                            <b-th>15</b-th>
                            <b-th>16</b-th>
                            <b-th>17</b-th>
                            <b-th>18</b-th>
                            <b-th>19</b-th>
                            <b-th>20</b-th>
                            <b-th>21</b-th>
                            <b-th>22</b-th>
                            <b-th>23</b-th>
                            <b-th>24</b-th>
                            <b-th>25</b-th>
                            <b-th>26</b-th>
                            <b-th>27</b-th>
                            <b-th>28</b-th>
                            <b-th>29</b-th>
                            <b-th>30</b-th>
                            <b-th>31</b-th>
                            <b-th>32</b-th>
                            <b-th>33</b-th>
                            <b-th>34</b-th>
                            <b-th>35</b-th>
                            <b-th>36</b-th>
                            <b-th>37</b-th>
                            <b-th>38</b-th>
                            <b-th>39</b-th>
                            <b-th>40</b-th>
                            <b-th>41</b-th>
                            <b-th>42</b-th>
                            <b-th>43</b-th>
                            <b-th>44</b-th>
                            <b-th>45</b-th>
                            <b-th>46</b-th>
                            <b-th>47</b-th>
                            <b-th>48</b-th>
                            <b-th>49</b-th>
                            <b-th>50</b-th>
                            <b-th>51</b-th>
                            <b-th>52</b-th>
                            <b-th>53</b-th>
                            <b-th>54</b-th>
                            <b-th>55</b-th>
                            <b-th>56</b-th>
                            <b-th>57</b-th>
                            <b-th>58</b-th>
                            <b-th>59</b-th>
                            <b-th>60</b-th>
                            <b-th>61</b-th>
                        </b-tr> -->
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td>{{ idx + 1 }}</b-td>
                        <b-td>{{ item.kd_diag }}</b-td>
                        <b-td>{{ item.nm_diag }}</b-td>
                        <b-td>{{ item['a0_7hr']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a0_7hr']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a0_7hr']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a0_7hr']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a8_28hr']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a8_28hr']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a8_28hr']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a8_28hr']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a29hr_1th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a29hr_1th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a29hr_1th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a29hr_1th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a1_4th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a1_4th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a1_4th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a1_4th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a5_9th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a5_9th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a5_9th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a5_9th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a10_14th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a10_14th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a10_14th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a10_14th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a15_19th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a15_19th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a15_19th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a15_19th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a20_44th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a20_44th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a20_44th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a20_44th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a45_54th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a45_54th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a45_54th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a45_54th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a55_59th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a55_59th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a55_59th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a55_59th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a60_64th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a60_64th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a60_64th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a60_64th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a65_69th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a65_69th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a65_69th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a65_69th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['a70th']['baru']['L'] }}</b-td>
                        <b-td>{{ item['a70th']['baru']['P'] }}</b-td>
                        <b-td>{{ item['a70th']['lama']['L'] }}</b-td>
                        <b-td>{{ item['a70th']['lama']['P'] }}</b-td>
                        <b-td>{{ item['jumlah']['baru']['L'] }}</b-td>
                        <b-td>{{ item['jumlah']['baru']['P'] }}</b-td>
                        <b-td>{{ item['jumlah']['lama']['L'] }}</b-td>
                        <b-td>{{ item['jumlah']['lama']['P'] }}</b-td>
                        <b-td>{{ item['jumlah']['total']['L'] }}</b-td>
                        <b-td>{{ item['jumlah']['total']['P'] }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>
  
<script>
  export default {
    name: "kunjungan_pasien",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        data_search: {
          bulan_awal: null,
          tahun_awal: null,
          bulan_akhir: null,
          tahun_akhir: null,
        },
        items: [],
        checked: false,
        checked_poli: "",
        select_puskesmas: [],
        select_poli: [],
        options_puskesmas: [],
        options_poli: [],
        option_bulan: [
        {value: 1, text: "Januari"},
        {value: 2, text: "Februari"},
        {value: 3, text: "Maret"},
        {value: 4, text: "April"},
        {value: 5, text: "Mei"},
        {value: 6, text: "Juni"},
        {value: 7, text: "Juli"},
        {value: 8, text: "Agustus"},
        {value: 9, text: "September"},
        {value: 10, text: "Oktober"},
        {value: 11, text: "November"},
        {value: 12, text: "Desember"},
      ],

      option_tahun: [
        {value: 2020, text: "2020"},
        {value: 2021, text: "2021"},
        {value: 2022, text: "2022"},
        {value: 2023, text: "2023"},
        {value: 2024, text: "2024"},
        {value: 2025, text: "2025"},
      ],
        
      };
    },
    activated(){
      // this.totalRows = this.items.length;
      this.data_search.bulan_akhir = this.$moment(new Date()).format("MM")
      this.data_search.bulan_awal = this.$moment(new Date()).format("MM")
      this.data_search.tahun_akhir = this.$moment(new Date()).format("YYYY")
      this.data_search.tahun_awal = this.$moment(new Date()).format("YYYY")
      this.getDataKunjungan();
      this.getData()
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData(){
        let vm = this
        let puskesmas = await vm.$axios.post("/puskesmas/list")
        // console.log('puskesmas', puskesmas)
        if(puskesmas.data.data.length){
            vm.options_puskesmas = puskesmas.data.data
            for (let i = 0; i < vm.options_puskesmas.length; i++) {
                let x = vm.options_puskesmas[i];
                x.checked = false
            }
        }
        let poli = await vm.$axios.post("/ms_poli/list")
        if(poli.data.data.length){
            vm.options_poli = poli.data.data
            for (let i = 0; i < vm.options_poli.length; i++) {
                let x = vm.options_poli[i];
                x.checked = false
            }
        }
      },
      toggleSelect: function () {
            let select = this.checked;
            console.log(this.checked);
            this.options_puskesmas.forEach(function (data) {
                data.checked = select;
            });
            this.select_puskesmas = []
            for (let i = 0; i < this.options_puskesmas.length; i++) {
              let x = this.options_puskesmas[i];
              if(x.checked == true){
                this.select_puskesmas.push(x.puskesmas_id)
              }
            }
            this.$forceUpdate()
        },
        toggleSelectPoli: function () {
            let select = this.checked_poli;
            // console.log(this.checked_poli);
            this.options_poli.forEach(function (data) {
                data.checked = select;
            });
            this.select_poli = []
            for (let i = 0; i < this.options_poli.length; i++) {
              let x = this.options_poli[i];
              if(x.checked == true){
                this.select_poli.push(x.ms_poli_id)
              }
            }
            this.$forceUpdate()
        },
        addSelect(){  
          let vm = this
          vm.select_puskesmas = []
          for (let i = 0; i < vm.options_puskesmas.length; i++) {
              let x = vm.options_puskesmas[i];
              if(x.checked == true){
                vm.select_puskesmas.push(x.puskesmas_id)
              }
            }
        },
        addSelectPoli(){  
          let vm = this
          vm.select_poli = []
          for (let i = 0; i < vm.options_poli.length; i++) {
              let x = vm.options_poli[i];
              if(x.checked == true){
                vm.select_poli.push(x.ms_poli_id)
              }
            }
        },
      getDataKunjungan(){
        let vm = this
        vm.$axios.post("/pool_diagnosa/laporan3lb1", {
          bulan_awal: parseInt(vm.data_search.bulan_awal),
          bulan_akhir: parseInt(vm.data_search.bulan_akhir),
          tahun_awal: parseInt(vm.data_search.tahun_awal),
          tahun_akhir: parseInt(vm.data_search.tahun_akhir),
          bulk_puskesmas_id: vm.select_puskesmas
        })
        .then((res) => {
          console.log(res, 'ini respon');
          if(res.data.diagnosa.length){
            vm.items = res.data.diagnosa
            console.log(vm.items[0].a0_7hr.baru.L, 'iniii');
          }
        })
        .catch((err) => {
          console.log(err);
        })
      },
      
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  };
  </script>
  <style>

  #page-lap-sp3lb1 .custom-control-label{
    padding-top:2.5px
  }

  #page-lap-sp3lb1 .custom-table tr, th, td{
    text-align:center;
    border:1px solid #e0e0e0 !important;
  }
  #page-lap-sp3lb1 .custom-table{
    /* height:660px; */
  }
  #page-lap-sp3lb1 .custom-table thead{
    position:sticky;
    top:0;
    background-color:#D52F65;
    color:#fff;
  }

  /* #page-input-pemeriksaan-lab .table-pemeriksaan-lab th:nth-child(1) {
    border-top-left-radius: 8px;
  }

  #page-input-pemeriksaan-lab .table-pemeriksaan-lab th:nth-child(7) {
    border-top-right-radius: 8px;
  } */
  </style>
  